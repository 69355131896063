import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '../../components/localizedLink'
import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';
import {filterByLang, slugify} from '../../utility/utils'
import {FormattedMessage} from 'react-intl'
import pages from '../../utility/pages';
import Layout from '../../components/layout'
import SEO from '../../components/seo'


const Artwork = ({ pageContext: { locale } })  => {
  const { allContentfulLavoriPerClienti } = useStaticQuery(
    graphql`
      query {
        allContentfulLavoriPerClienti(sort: {fields: [dataOrdinamento], order: DESC}) {
          edges {
            node {
              titolo
              node_locale
              copertina {
                title
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  ); 
  const lavori = filterByLang(allContentfulLavoriPerClienti,locale);
  return (
    <Layout locale={locale}>
      <SEO title="Lavori per clienti" />
      <div className="row">
        <div className="col-12 text-center">
          <FormattedMessage id="clienti">
              {txt => <h1 className="pagetitle">{txt}</h1>}
          </FormattedMessage>
        </div>
      </div>
      <div className="section section--top">
        <div className="row">
          {lavori.map((item,index) => {
            return (
              <div key={index} className="col-6 col-lg-4">
                <div className="card">
                  <Link to={`${pages[locale]('/i-miei-lavori/')}${slugify(item.titolo)}/`} className="card__copertinawrapper imghover">
                    <GatsbyImage alt={item.titolo} title={item.titolo} className="card__copertina" fluid={item.copertina.fluid} />
                  </Link>
                  <div className="card__title text-center">
                    <Link to={`${pages[locale]('/i-miei-lavori/')}${slugify(item.titolo)}/`}>{item.titolo}</Link>
                  </div>
                </div>
              </div> 
            )
            
          })}
        </div>
      
      </div>
    </Layout>
  )
}
export default Artwork